import { useLocation } from '@reach/router';
import { Analytics } from 'apis/Analytics';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { Question, QuizAnswer } from 'types/quiz';
import { getUnixDate } from './functions';
import { GAPageView } from 'types/analyticsTypes';
import Tracking from './tracking';

const MIN_SCORE = 10;
const MAX_SCORE = 40;

interface QueryParams {
  qz?: string;
  locale?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_term?: string;
  campaign_id?: string;
  adset_id?: string;
  ad_id?: string;
  placement?: string;
  fbc_id?: string;
  h_ad_id?: string;
  utm_id?: string;
  fbclid?: string;
}

export const useQuizData = (page: string) => {
  const [quizData, setQuizData] = useState<any>(null);
  const config = useSelector((state: AppState) => state.config);

  const getQuizData = useCallback(() => {
    const success = config?.[page];

    setQuizData(success);
  }, [config]);

  useEffect(() => {
    if (!config) {
      return;
    }
    getQuizData();
  }, [config]);

  return quizData;
};

export const useCheckoutData = () => {
  const isDiscount = !!(
    (location as { state?: { discount?: string } })?.state?.discount ||
    location?.pathname?.includes('/checkout-special')
  );

  const quizData = useQuizData(isDiscount ? 'checkoutSpecial' : 'checkout');
  return { data: quizData, isDiscount };
};

export const useQuizWeightResult = (
  minScore: number = MIN_SCORE,
  maxScore: number = MAX_SCORE,
) => {
  const quizQuestions = useSelector(
    (state: AppState) => state.funnel.questions,
  );
  const quizAnswers = useSelector((state: AppState) => state.user.quiz_answers);

  const quizQuestionsObj = useMemo(() => {
    const obj: Record<string, Question> = {};

    quizQuestions.forEach(question => {
      obj[question.key] = question;
    });

    return obj;
  }, [quizQuestions]);

  const weightSum = useMemo(
    () =>
      Object.keys(quizAnswers).reduce((sum: number, key: string) => {
        const answers = quizAnswers[key].split('|');
        const matchingQuestion = quizQuestionsObj[key] as Question;

        if (!matchingQuestion) return sum;

        answers.forEach((answer: string) => {
          matchingQuestion.options?.forEach(option => {
            if (option?.value?.toLowerCase() === answer?.toLowerCase()) {
              sum += parseInt(option.score) || 0;
            }
          });
        });

        return sum;
      }, 0),
    [quizAnswers, quizQuestionsObj],
  );

  const totalSum = useMemo(
    () =>
      quizQuestions.reduce((totalSum: number, question: unknown) => {
        // if no question options - returns current total sum
        if (!question) {
          return totalSum;
        }
        if (!question.options) {
          return totalSum;
        }
        // if multiple options - returns those options sum
        if (question.type === 'multiple') {
          const multipleQuestionSum = question.options.reduce(
            (sum: number, option: { score?: number }) =>
              sum + (option.score || 0),
            0,
          );

          return totalSum + multipleQuestionSum;
        }
        // if single option - returns highest option
        const highestSingleQuestion = Math.max(
          ...question.options.map(
            (option: { score: number }) => option.score || 0,
          ),
        );
        return highestSingleQuestion + totalSum;
      }, 0),
    [quizQuestions],
  );

  return {
    sum:
      weightSum < minScore
        ? minScore
        : weightSum > maxScore
        ? maxScore
        : weightSum,
    total:
      totalSum < minScore
        ? maxScore
        : totalSum > maxScore
        ? maxScore
        : totalSum,
  };
};

export const useQuizAnswers = () => {
  const answers: QuizAnswer = useSelector((s: AppState) => s.user.quiz_answers);
  return { answers };
};

export const usePageView = (optionalData?: GAPageView) => {
  const { geolocation, user_ip } = useSelector((s: AppState) => s.user);
  const email = useSelector((s: AppState) => s.user.user?.email);
  const { pathname, search } = useLocation();
  const pageUrl = pathname + search;

  useEffect(() => {
    Tracking.virtualPageView(
      {
        event: 'virtualPageview',
        pageUrl: pageUrl,
        pageTitle: pathname,
        location: geolocation?.iso_country?.toLocaleLowerCase(),
        email: email || '',
      },
      optionalData,
      user_ip || '',
    );
  }, [pageUrl, pathname]);
};

export const useFirstVisitDate = () => {
  const { firstVisitDate } = useSelector((state: AppState) => state.user);
  const countdownDiffInMS = (getUnixDate() - firstVisitDate) * 1000;
  return { countdownDiffInMS };
};

export const useTrackingQueryParams = (prefix: string = ''): QueryParams => {
  const location = useLocation();
  const iso_country = useSelector(
    (state: AppState) => state?.user?.geolocation?.iso_country,
  );
  const state_name = useSelector(
    (state: AppState) => state?.user?.geolocation?.state,
  );

  const getSpecificQueryParams = (): QueryParams => {
    // List of keys to extract
    const keysToExtract: string[] = [
      'qz',
      'locale',
      'utm_source',
      'utm_medium',
      'utm_campaign',
      'utm_content',
      'utm_term',
      'campaign_id',
      'adset_id',
      'ad_id',
      'placement',
      'fbc_id',
      'h_ad_id',
      'utm_id',
      'fbclid',
      'test',
      'anotherTest',
    ];

    const queryString = location.search.substring(1);
    const paramsArray = queryString.split('&');

    const paramsObj: QueryParams = {};
    paramsArray.forEach(param => {
      const [key, value] = param.split('=');
      const decodedKey = decodeURIComponent(key) as keyof QueryParams;
      if (keysToExtract.includes(decodedKey)) {
        paramsObj[
          (prefix ? `${prefix}${decodedKey}` : decodedKey) as keyof QueryParams
        ] = decodeURIComponent(value);
      }
    });

    if (iso_country) {
      const iso_country_key = `${prefix}iso_country` as keyof QueryParams;
      paramsObj[iso_country_key] = iso_country;
    }

    if (state_name) {
      const state_name_key = `${prefix}state_name` as keyof QueryParams;
      paramsObj[state_name_key] = state_name;
    }

    return paramsObj;
  };

  return getSpecificQueryParams();
};
