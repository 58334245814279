import React, { FC, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Question, QuizAnswer } from 'types/quiz';
import { DynamicImage, Text } from 'components';
import {
  desktop,
  mobile,
  sxMobile,
  tablet,
  useQuery,
} from 'styles/breakpoints';
import PrimaryButton from 'components/PrimaryButton';
import LCFooter from 'components/LCFooter';
import socProof from 'assets/images/landing/mobile_landing.png';
import socProof_des from 'assets/images/landing/desktop_landing.png';
import Logo from 'assets/icons/logo/logo.svg';
import BlackFridayBanner from 'components/BlackFridayBanner';
import { useFirstVisitDate, usePageView } from 'utils/hooks';
import BlackFridayLandingLabel from './BlackFridayLandingLabel';
import Tracking from 'utils/tracking';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'state/types';
import { updateQuizAnswers, updateQuizFlowAnswers } from 'state/user/effects';
import { resetQuizAnswers } from 'state/user/actions';
import { useLocation } from '@reach/router';
import { normalizeStates } from 'utils/localization';

interface LandingProps {
  question: Question;
  onOptionSelect: (answer: QuizAnswer) => void;
  hideFooter?: boolean;
}

interface ButtonsContainerProps {
  length: number;
}

const Landing: FC<LandingProps> = ({
  question,
  onOptionSelect,
  hideFooter = false,
}) => {
  const { isTablet } = useQuery();
  const { countdownDiffInMS } = useFirstVisitDate();
  const location = useLocation();
  const questionParam = new URLSearchParams(location.search).get('q');
  const bfBannerExists = question?.bfBanner;
  const quiz_flow_answers = useSelector(
    (state: AppState) => state.user?.quiz_flow_answers,
  );
  const quiz_answers = useSelector(
    (state: AppState) => state.user?.quiz_answers,
  );

  const geo = useSelector((state: AppState) => state.user?.geolocation);
  const dispatch = useDispatch();

  usePageView({
    city: encodeURIComponent(
      geo?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    country: geo?.iso_country?.toLowerCase(),
    state: normalizeStates(geo?.iso_country || '', geo?.iso_state || ''),
    gender: quiz_answers?.gender ? quiz_answers?.gender[0] : null,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.href, location.pathname]);

  const pickSocialProfImage = (variant?: string) =>
    // if (variant === 'blackFriday') {
    //   return {
    //     mob: bfSocProof,
    //     des: bfSocProof_des,
    //   };
    // }
    // if (variant === 'xmas') {
    //   return {
    //     mob: xmasSocProof,
    //     des: xmasSocProof_des,
    //   };
    // }
    // if (variant === 'ny') {
    //   return {
    //     mob: nySocProof,
    //     des: nySocProof_des,
    //   };
    // }
    ({
      mob: socProof,
      des: socProof_des,
    });
  const handleOptionsSelect = (item: any) => {
    const logEvent = (data: { category: string; label?: string }) => {
      Tracking.trackQuizQuestionAnswered({
        event: `Quiz question answered - 1`,
        category: `1 - ${data.category}`,
        label: data.label || '',
      });
    };

    logEvent({
      category: `${question.label}${
        question.optionsLabel ? ` - ${question.optionsLabel}` : ''
      }`,
      label: String(item?.label),
    });

    onOptionSelect({
      [question?.key]: item?.gender,
      label: item?.label,
    });
  };

  const renderGenderButtons = (options: any[]) => (
    <>
      {options?.map((option, index) => (
        <GenderButton
          key={index}
          color={option?.color}
          fontColor={option?.textColor}
          colorActive={option?.color}
          onClick={() =>
            handleOptionsSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          marginRight={true}
          isSingleButton={question?.options?.length === 1}
        >
          {option?.label}
        </GenderButton>
      ))}
    </>
  );

  const renderGenderBasic = (options: any[]) => {
    if (!options.length) {
      return null;
    }
    return options?.map((option, index) => {
      const hasColor = !!option.color;
      return (
        <OtherGenderButton
          onClick={() =>
            handleOptionsSelect({
              [question?.key]: option?.value,
              label: option?.label,
            })
          }
          key={index}
        >
          {hasColor ? (
            option?.label
          ) : (
            <Text type="body500">{option?.label}</Text>
          )}
        </OtherGenderButton>
      );
    });
  };

  const renderGenderOptions = () => {
    const primaryOptions = question?.options?.slice(0, 2) || [];
    const secondaryOptions = question?.options?.slice(2) || [];
    return (
      <ButtonsContainer
        length={[...primaryOptions, ...secondaryOptions].length}
      >
        {renderGenderButtons(primaryOptions)}
        {renderGenderBasic(secondaryOptions)}
      </ButtonsContainer>
    );
  };
  return (
    <>
      <Container hideFooter={hideFooter}>
        {/* {bfBannerExists ? (
          <BlackFridayBannerStyled
            {...question.bfBanner}
            countdownDiffInMS={countdownDiffInMS}
          />
        ) : null} */}
        <Header top={bfBannerExists ? '4.5rem' : 0} color="coldGray">
          <HeaderImg src="assets/icons/logo.svg" alt="" />
        </Header>
        <Wrapper>
          <W1>
            <SocialProof
              mob={pickSocialProfImage(question?.socialProofVariant).mob}
              des={pickSocialProfImage(question?.socialProofVariant).des}
            />
          </W1>

          <WrappedItem>
            <W3>
              <TitleContainer>
                <Title
                  textAlign={'left'}
                  dangerouslySetInnerHTML={{ __html: question?.title || '' }}
                />
              </TitleContainer>

              <DescriptionContainer>
                <Label
                  textAlign={'left'}
                  dangerouslySetInnerHTML={{
                    __html: question?.description || '',
                  }}
                  descriptionIsMaxWidth={question?.descriptionIsMaxWidth}
                />
              </DescriptionContainer>

              <ButtonsTitleContainer>
                <ButtonsLabel
                  dangerouslySetInnerHTML={{ __html: question?.label }}
                  textAlign={'left'}
                />
              </ButtonsTitleContainer>
              {renderGenderOptions()}
              {question?.bfLabel ? (
                <BlackFridayLandingLabel {...question.bfLabel} />
              ) : null}
            </W3>
          </WrappedItem>
        </Wrapper>

        {hideFooter ? null : (
          <LCFooter disclaimerText={question?.disclaimerParagraphs || []} />
        )}
      </Container>
    </>
  );
};

export default Landing;

const Wrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 50%;
  flex-wrap: wrap;
`;

const Circle = styled.div`
  display: flex;
  border: 0.5px solid #2a4b42;
  border-radius: 180px;
  height: 2.5rem;
  width: 2.5rem;
  align-items: center;
  justify-content: center;
`;

const CircleScience = styled.div`
  display: flex;
  border: 0.5px solid #2a4b42;
  border-radius: 180px;
  height: 2.5rem;
  width: 2.8rem;
  align-items: center;
  justify-content: center;
`;

const StyledText = styled(Text)`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 130%; /* 0.975rem */
  letter-spacing: -0.018rem;
  color: #2a4b42;
  @media ${sxMobile} {
    font-size: 0.5rem;
  }
`;

const ItemWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  max-width: 9.375rem;
  width: 100%;
  margin: 0 0 1rem;
`;

const IconContainer = styled.div`
  display: flex;
  max-width: 38.8rem;
  width: 100%;
  justify-content: space-between;
  margin-top: 1.25rem;
  flex-wrap: wrap;
  @media ${mobile} {
    margin-top: unset;
  }
`;

const W3 = styled.div`
  max-width: 720px;
  width: 100%;
  margin-left: 48px;
  @media ${tablet} {
    margin-right: unset;
    margin: auto;
  }
`;

const W1 = styled.div`
  display: flex;
  flex: 1;
  max-width: 50%;

  @media ${tablet} {
    max-width: 100%;
    flex: 0;
    padding: 0;
  }
`;

const Container = styled.div<{ hideFooter?: boolean }>`
  display: flex;
  flex-direction: column;
  min-height: ${({ hideFooter }) => (hideFooter ? 'none' : '100vh')};
  background: #f3f3f8;
`;

const WrappedItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: inherit;
  margin-left: auto;
  padding-top: 72px;
  @media ${tablet} {
    padding: 0 1rem;
    width: unset;
    margin-left: unset;
    flex: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex: 1;
  gap: 3rem;
  width: 100%;
  // max-width: 1440px;
  margin-left: auto;
  background: ${({ theme }) => theme.colors.coldGrey};

  @media ${tablet} {
    flex-direction: column;
    align-items: unset;
    gap: 1.5rem;
  }
`;

const HeaderImg = styled(Logo)`
  height: 2.44rem;
  width: 13.9rem;
  @media ${tablet} {
    height: 2rem;
    width: 6.41rem;
  }
`;

const Header = styled.div<{ top?: string }>`
  position: absolute;
  top: ${({ top = '0' }) => top};
  display: flex;
  align-items: center;
  padding: 1.5rem 3rem;
  margin: 0 auto;
  width: 100%;

  @media ${tablet} {
    position: static;
    top: auto;
    align-items: center;
    justify-content: center;
    padding: 1.25rem 0 1.06rem;
  }
`;

const slideV = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 10000%;
  }
`;

const slideH = keyframes`
  from {
    background-position: 0 0;
  }
  to {
    background-position: 10000% 0;

  }
`;

// const SocialProof = styled.div`
//   position: relative;
//   background-size: cover;
//   animation: ${slideV} 1500s linear infinite;
//   width: 100%;
//   background-image: url(${socProof_des});
//   height: 80vh;

//   @media ${tablet} {
//     background-image: url(${socProof});
//     animation: ${slideH} 1500s linear infinite;
//     height: 300px;
//   }
// `;

const SocialProof = styled.div<{ des: string; mob: string }>`
  position: relative;
  background-size: cover;
  animation: ${slideV} 1500s linear infinite;
  width: 100%;
  background-image: url(${socProof_des});
  height: calc(100vh - 8vh);
  @media ${tablet} {
    animation: ${slideH} 1500s linear infinite;
    background-image: url(${socProof});
    height: 308px;
  }
`;

const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  flex-wrap: wrap;
  @media ${tablet} {
    align-items: center;
    padding-bottom: 24px;
    justify-content: center;
  }
`;

const Title = styled(Text)`
  color: #000;
  font-size: 3rem;
  font-weight: 700;
  line-height: 3.75rem;
  span {
    color: #ef71a0;
  }
  @media ${tablet} {
    font-size: 1.625rem;
    font-weight: 600;
    line-height: 1.8125rem;
  }
`;

const Label = styled(Text)<{
  descriptionIsMaxWidth?: boolean;
  fontSizing?: number;
}>`
  color: #28293d;
  font-size: 1.125rem;
  @media ${tablet} {
    font-size: 1rem;
    line-height: 1.4375rem;
  }
`;
const ButtonsLabel = styled(Text)`
  color: #28293d;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 700;
  line-height: 140%;
  @media ${mobile} {
    font-size: 0.75rem;
    font-weight: 600;
    line-height: normal;
  }
`;

const DescriptionContainer = styled.div`
  padding-bottom: 3rem;
  max-width: 624px;
  @media ${tablet} {
    text-align: left;
    text-align-last: left;
    padding-bottom: 1.5rem;
    display: flex;
  }
`;

const TitleContainer = styled.div`
  padding-bottom: 1.5rem;
  justify-content: center;
  display: flex;
  width: 100%;

  @media ${tablet} {
    text-align: left;
    padding-bottom: 1rem;
  }
`;

const GenderButton = styled(PrimaryButton).attrs({
  cornerVariant: 'round',
})<{ marginRight?: boolean; isSingleButton?: boolean }>`
  padding: 0 1rem;
  max-width: 10.3125rem;
  width: 100%;
  height: 3.5rem;
  margin-right: 1rem;
  flex-basis: ${({ isSingleButton }) =>
    isSingleButton ? '100%' : 'calc((100% / 3) - 1rem)'};
  @media ${tablet} {
    height: 3rem;
    flex-basis: ${({ isSingleButton }) =>
      isSingleButton ? '100%' : 'calc(50% - 1rem)'};
    margin-right: ${({ marginRight }) => (marginRight ? 1 : 0)}rem;
  }
`;

const OtherGenderButton = styled(GenderButton)`
  flex-basis: calc(33% - 1rem);
  background-color: #f3f3f8;

  border: 1px solid #8e909a;
  div p {
    text-align: center;
    color: #8e909a;
  }
  @media ${tablet} {
    flex-basis: calc(100% - 1rem);
    margin-top: 1rem;
  }
`;

const ButtonsTitleContainer = styled.div`
  padding-bottom: 1.5rem;
  margin-right: 0;
  @media ${tablet} {
    text-align: center;
    padding-bottom: 0.75rem;
    display: flex;
  }
`;

const StyledHeaderText = styled(Text)`
  color: #436460;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
`;
