import { useRouter } from 'apis/history';
import * as React from 'react';
import { mobile, tablet, useQuery } from 'styles/breakpoints';
import { Text } from 'components';
import styled from 'styled-components';
import { Quiz } from 'types/quiz';
import Logo from 'assets/icons/quiz/logo-footer.svg';
import Fb from 'assets/icons/quiz/facebook.svg';
import Ig from 'assets/icons/quiz/instagram.svg';
import { config } from 'config';

interface FooterProps {
  disclaimerText: string[];
  whiteFooter?: boolean;
}

const LCFooter: React.FC<FooterProps> = ({
  disclaimerText,
  whiteFooter = false,
}) => {
  const { goToPrivacy, goToTerms, goToContact, goToLanding, goToFAQ } =
    useRouter();
  const { isTablet } = useQuery();
  const quiz = new URLSearchParams(location.search).get('qz') ?? Quiz.Main;

  return (
    <>
      <Container whiteFooter={whiteFooter}>
        <Wrapper>
          <Links>
            <LinkWrap onClick={() => goToLanding()}>
              <Logo />
            </LinkWrap>
            <LinkWrap
              onClick={() => window.open(config.WEB_APP_URL, '_system')}
            >
              <StyledTextBold type="bodyM600" color="light0">
                Log in
              </StyledTextBold>
            </LinkWrap>
            <LinkWrap onClick={() => goToFAQ()}>
              <StyledTextT type="bodyM600" color="light0">
                FAQ
              </StyledTextT>
            </LinkWrap>
            {quiz === Quiz.Main ? (
              <LinkWrap onClick={() => goToContact()}>
                <StyledTextT type="bodyM600" color="light0">
                  Contact Us
                </StyledTextT>
              </LinkWrap>
            ) : null}
            <LinkWrap onClick={() => goToTerms()}>
              <StyledTextT type="bodyM600" color="light0">
                Terms & Conditions
              </StyledTextT>
            </LinkWrap>
            <LinkWrap onClick={() => goToPrivacy()}>
              <StyledTextT type="bodyM600" color="light0">
                Privacy Policy
              </StyledTextT>
            </LinkWrap>
          </Links>

          <DisclaimerContainer>
            <Wrap>
              <IconsContainer>
                <a
                  href="https://www.facebook.com/people/Nordastro/61562597241042/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Fb />
                </a>
                <a
                  href="https://www.instagram.com/nordastro_official/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Ig />
                </a>
              </IconsContainer>
              {disclaimerText.map((paragraph, index) => (
                <StyledText
                  key={index}
                  type={isTablet ? 'bodyXM400' : 'bodyXM400'}
                  color="dark0"
                >
                  {paragraph}
                </StyledText>
              ))}
            </Wrap>
          </DisclaimerContainer>
        </Wrapper>
      </Container>
    </>
  );
};

export default LCFooter;

const IconsContainer = styled.div`
  display: flex;
  gap: 0.75rem;
`;

const Wrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
  padding-top: 1rem;
  @media ${tablet} {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: 1rem;
  }
`;

const Dot = styled.div`
  color: #fff;
`;

const DisclaimerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const DisclaimerWrap = styled.div`
  cursor: pointer;
`;

const StyledTextT = styled(Text)`
  color: #6a6a8c;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 140%;
  letter-spacing: -0.021rem;
  @media ${tablet} {
    font-size: 0.75rem;
    font-style: normal;
    line-height: normal;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  gap: 0.75rem;
  @media ${tablet} {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }
`;

const StyledText = styled(Text)`
  color: #73899c;
  text-align: center;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  @media ${tablet} {
    font-size: 0.75rem;
  }
`;

const StyledTextBold = styled(StyledTextT)`
  font-weight: 600 !important;
`;

const DisclaimerContainer = styled.div`
  width: 100%;
  border-top: 1px solid rgba(115, 137, 156, 0.2);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  @media ${tablet} {
    align-items: center;
    gap: 0.5rem;
  }
`;

const Container = styled.div<{ whiteFooter?: boolean }>`
  display: flex;
  padding: 1.5rem 0;
  gap: 1.5rem;
  background: ${({ whiteFooter }) => (whiteFooter ? '#fff' : '#F3F3F8')};
  @media ${tablet} {
    padding: unset;
    height: unset;
    text-align: center;
    padding: 1.5rem 1rem;
  }
`;

const Links = styled.div`
  color: #fff;
  display: flex;
  justify-content: center;
  gap: 2rem;
  height: 3rem;
  flex-wrap: wrap;
  @media ${tablet} {
    flex-direction: column;
    height: unset;
    gap: 1rem;
    width: 100%;
  }
`;

const LinkWrap = styled.div`
  cursor: pointer;
  white-space: nowrap;
  display: flex;
  align-items: center;
  @media ${tablet} {
  }
`;
